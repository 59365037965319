import { Component, effect, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  isSidebarOpen?:boolean=false
  current:any
  constructor(
    private helper: HelperService

  ) { 
    effect(()=>{
      this.isSidebarOpen = this.helper.toggleMenu();
   })
  }

  ngOnInit() {
  }

  toggleMenu() {
    this.helper.toggleMenu.update(v=> v = !this.isSidebarOpen);
  }

  openExternalLink(link:any) {
    window.open(link, '_self');
  }

}
