<div 
  [ngClass]="{
    'block opacity-100': isSidebarOpen, 
    'hidden opacity-0': !isSidebarOpen
  }"
  (click)="toggleMenu()"
  class="fixed inset-0 bg-black bg-opacity-50 z-20 transition-opacity duration-300 ">
</div>

<div 
[ngClass]="{
  'translate-x-0': isSidebarOpen, 
  '-translate-x-full': !isSidebarOpen,
  
}" 
class="fixed z-30 h-full w-full bg-[#fff] text-[#323232] transition-transform duration-300 transform font-speedee">
<div class="px-4 pb-3">
  <div class="flex items-center h-[60px]">
    <img class="w-[32px]" src="assets/media/logo.svg" alt="">
    <div class="ml-auto" (click)="toggleMenu()">
      <img class="w-[20px]" src="assets/media/icon-close.svg" alt="">
    </div>
  </div>
  <ul class="text-[24px] font-bold">
    <li class="py-2">
      <a class="flex items-center hover:text-[#FDBB20] active:text-[#FDBB20]" (click)="openExternalLink('https://www.mcdonalds.co.id/menu?utm_source=microsite-pahlawan-disekitar-kita&utm_medium=referral&utm_campaign=mcd-pahlawan-sekitarkita')">
        <span class="mr-auto" [ngClass]="{'h-active' : current == 'menu'}" >Menu</span>
        <img class="w-[24px]" src="assets/media/icon-arrow.svg" alt="">
      </a>
    </li>
    <li class="py-2">
      <a class="flex items-center hover:text-[#FDBB20] active:text-[#FDBB20]" (click)="openExternalLink('https://www.mcdonalds.co.id/promo?utm_source=microsite-pahlawan-disekitar-kita&utm_medium=referral&utm_campaign=mcd-pahlawan-sekitarkita')">
        <span class="mr-auto" [ngClass]="{'h-active' : current == 'promo'}" >Promo</span>
        <img class="w-[24px]" src="assets/media/icon-arrow.svg" alt="">
      </a>
    </li>
    <li class="py-2">
      <a class="flex items-center hover:text-[#FDBB20] active:text-[#FDBB20] "(click)="openExternalLink('https://www.mcdonalds.co.id/dunia-anak?utm_source=microsite-pahlawan-disekitar-kita&utm_medium=referral&utm_campaign=mcd-pahlawan-sekitarkita')">
        <span class="mr-auto" [ngClass]="{'h-active' : current == 'dunia'}" >Dunia Anak</span>
        <img class="w-[24px]" src="assets/media/icon-arrow.svg" alt="">
      </a>
    </li>
    <li class="py-2">
      <a class="flex items-center hover:text-[#FDBB20] active:text-[#FDBB20]" (click)="openExternalLink('https://www.mcdonalds.co.id/whats-on?utm_source=microsite-pahlawan-disekitar-kita&utm_medium=referral&utm_campaign=mcd-pahlawan-sekitarkita')">
        <span class="mr-auto" [ngClass]="{'h-active' : current == 'berita'}" >Berita Terkini</span>
        <img class="w-[24px]" src="assets/media/icon-arrow.svg" alt="">
      </a>
    </li>
    <li class="py-2">
      <a class="flex items-center hover:text-[#FDBB20] active:text-[#FDBB20]" (click)="openExternalLink('https://www.aksi-hebat.mcdonalds.co.id')">
        <span class="mr-auto" [ngClass]="{'h-active' : current == 'pahlawan'}" >Pahlawan di Sekitar Kita</span>
        <img class="w-[24px]" src="assets/media/icon-arrow.svg" alt="">
      </a>
    </li>
    <li class="py-2">
      <a class="flex items-center" (click)="openExternalLink('https://www.mcdonalds.co.id/makin-kenal-makin-sayang?utm_source=microsite-pahlawan-disekitar-kita&utm_medium=referral&utm_campaign=mcd-pahlawan-sekitarkita')">
        <span class="mr-auto hover:text-[#FDBB20]" [ngClass]="{'h-active' : current == 'makin'}" >Makin Kenal Makin Sayang</span>
        <img class="w-[24px]" src="assets/media/icon-arrow.svg" alt="">
      </a>
    </li>
    <li class="py-2">
      <a class="flex items-center hover:text-[#FDBB20] active:text-[#FDBB20]" (click)="openExternalLink('https://www.mcdonalds.co.id/locations?utm_source=microsite-pahlawan-disekitar-kita&utm_medium=referral&utm_campaign=mcd-pahlawan-sekitarkita')">
        <span class="mr-auto" [ngClass]="{'h-active' : current == 'lokasi'}" >Lokasi</span>
        <img class="w-[24px]" src="assets/media/icon-arrow.svg" alt="">
      </a>
    </li>
  </ul>





</div>
</div>